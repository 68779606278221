import { FC, useEffect } from 'react'
import clsx from 'clsx'
import { Trans } from 'react-i18next'
import { ButtonNew, PremiumCrownIcon } from 'boards-web-ui'

import usePreviewNode from '@features/nodePreview/hooks/usePreviewNode'
import PageNodes from '@features/nodes/PageNodes'
import PreviewModal from '@features/templateGallery/components/PreviewModal'
import { ScreenNames } from '@features/analytics/useNodeEvents'
import { TemplateModel } from '@features/templateGallery/models/Template'
import { IconWrapper } from '@ui/components/IconWrapper'
import { Folder } from 'app/models'

import styles from './TemplatePreview.module.css'

interface Props {
  nid: string
  hasCrown: boolean
  templates: TemplateModel[]
  onChooseThisTemplateClick: (selectedPage: Folder) => void
}
const TemplatePreview: FC<Props> = ({
  nid,
  hasCrown,
  templates,
  onChooseThisTemplateClick,
}) => {
  const folder = {
    id: '',
    icon: '',
    title: '',
    text: '',
    shortcut: '',
    isPage: false,
    content: templates.map((t) => ({ ...t.data, type: 'page' })) as unknown,
  } as Folder
  const { node, prev, next } = usePreviewNode(nid, folder)
  const selectedPage = folder.content.find(
    (t) => t.id === node?.source.id,
  ) as unknown as Folder

  useEffect(() => {
    const bind = (e: KeyboardEvent) => {
      if (next && e.key === 'ArrowRight') {
        next()
      }
      if (prev && e.key === 'ArrowLeft') {
        prev()
      }
    }

    document.addEventListener('keyup', bind)

    return () => {
      document.removeEventListener('keyup', bind)
    }
  }, [next, prev])

  if (!selectedPage) return null

  return (
    <PreviewModal
      nid={selectedPage.id}
      folder={folder}
      next={next}
      prev={prev}
      controls={
        <div className={styles.ButtonWrapper}>
          <ButtonNew
            color="primary"
            size="medium"
            startIcon={
              hasCrown && (
                <IconWrapper>
                  <PremiumCrownIcon />
                </IconWrapper>
              )
            }
            onClick={() => onChooseThisTemplateClick(selectedPage)}
          >
            <Trans i18nKey={'page_preview_choose_this_template'} />
          </ButtonNew>
        </div>
      }
    >
      <div className={styles.Root} onClick={(e) => e.stopPropagation()}>
        <div className={clsx(styles.Page)}>
          <PageNodes
            nodes={selectedPage?.content}
            screen={ScreenNames.PAGE_PREVIEW}
          />
        </div>
      </div>
    </PreviewModal>
  )
}

export default TemplatePreview
