import React, { useEffect, useState } from 'react'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'

import useAppEvents from '@features/analytics/useAppEvents'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

import useBulk from '@hooks/bulk/useBulk'
import clsx from 'clsx'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { PremiumIndicationIcon } from 'boards-web-ui'
import useBoard from '../../../../hooks/useBoard'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import useEditTranslations from '../hooks/useEditTranslations'
import useRouterParams, { TabType } from '../../../../hooks/useRouterParams'

import * as ROUTES from '../../../constants/routes'
import Avatar from '../../../../elements/Avatar'

import useSubscriptionPlans from '../../payments/hooks/useSubscriptionPlans'

import styles from './EditorNav.module.css'

type Props = {
  onLinkClicked?: () => void
}
const EditorNav: React.FC<React.PropsWithChildren<Props>> = ({
  onLinkClicked,
}) => {
  const { insightsTabClick, contentTabClick, insightsTabLockedClick } =
    useAppEvents()
  const { contentTabTitle, insightsTabTitle, insightsTabLink } =
    useEditTranslations()
  const { search } = useLocation()
  const { selectedTab } = useRouterParams()

  const { status: planStatus } = useSubscriptionPlans()

  const { boardId, folderId, board, isOwnBoard, isEditor } = useBoard()

  const { bulk, resetBulkActions, isSelectionModeEnabled } = useBulk()

  const { insightsFeatureFlag } = useFeatureFlags()
  const hasInsightsTab = insightsFeatureFlag && (isOwnBoard || isEditor)

  const [lastFolderId, setLastFolderId] = useState(folderId)

  const { profile } = useProfileInformation()
  const { openPaywall } = usePaywall()

  const toInsides = () => {
    setLastFolderId(folderId)
    insightsTabClick()

    if (onLinkClicked) {
      onLinkClicked()
    }
  }

  const getContentLink = (): string => {
    if (selectedTab === TabType.CONTENT && board?.rootFolderId) {
      return `${ROUTES.createFolderLink(boardId, board.rootFolderId)}${search}`
    }

    return `${ROUTES.createFolderLink(boardId, lastFolderId)}${search}`
  }

  const toContent = () => {
    contentTabClick()
    if (onLinkClicked) {
      onLinkClicked()
    }
  }

  useEffect(() => {
    if (selectedTab === TabType.CONTENT) {
      setLastFolderId(folderId)
    }

    // reset selection if navigate to another folder
    if (bulk.nodes.length) {
      resetBulkActions()
    }

    // eslint-disable-next-line
  }, [folderId])

  const { isFreePlanUser } = useReverseTrialContext()
  const analyticsDisabled = isSelectionModeEnabled

  const analyticsClassNames = clsx(
    styles.Link,
    analyticsDisabled && styles.LinkDisabled,
  )

  if (hasInsightsTab) {
    return (
      <div className={styles.Root}>
        <RouterLink
          className={
            selectedTab === TabType.CONTENT
              ? [styles.Link, styles.Active].join(' ')
              : styles.Link
          }
          to={getContentLink()}
          onClick={toContent}
        >
          {contentTabTitle()}
        </RouterLink>

        {!isFreePlanUser &&
          (board?.options?.insightsAllowed ||
            profile.plan?.features?.analytics) && (
            <RouterLink
              className={clsx(
                styles.Link,
                selectedTab === TabType.INSIGHTS && styles.Active,
              )}
              to={`${ROUTES.createInsightsLink(boardId)}${search}`}
              onClick={toInsides}
            >
              {insightsTabTitle()}
            </RouterLink>
          )}

        {((!board?.options?.insightsAllowed &&
          !profile.plan?.features?.analytics) ||
          isFreePlanUser) && (
          <a
            className={analyticsClassNames}
            href={insightsTabLink()}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              if (planStatus !== 'loaded' || analyticsDisabled) {
                e.preventDefault()
                return
              }

              if (profile.plan.monetized) {
                e.preventDefault()
                e.stopPropagation()

                openPaywall(PaywallSourceEnum.ANALYTICS)
              }

              insightsTabLockedClick()
            }}
          >
            {insightsTabTitle()}
            &nbsp;{' '}
            <Avatar size={22} bgColor={'#E8EBFF'}>
              <PremiumIndicationIcon />
            </Avatar>
          </a>
        )}
      </div>
    )
  }

  return null
}

export default EditorNav
