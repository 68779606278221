import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useRotesHelper from './useRotesHelper'
import * as ROUTES from '../../constants/routes'

const useAutoSelectFirstOrderBoard = () => {
  const location = useLocation()

  const { nonBoardSelectedState, firstBoardUrl, navigateToFirstBoard } =
    useRotesHelper()

  useEffect(() => {
    if (
      nonBoardSelectedState &&
      firstBoardUrl &&
      location.pathname !== `/${ROUTES.TEMPLATE_GALLERY}`
    ) {
      navigateToFirstBoard()
    }

    // eslint-disable-next-line
  }, [nonBoardSelectedState, firstBoardUrl])

  return !nonBoardSelectedState
}

export default useAutoSelectFirstOrderBoard
