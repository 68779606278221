import { FC } from 'react'
import { If, ImagePicker, InputField, Spacer } from 'boards-web-ui'
import { useTranslation } from 'react-i18next'

import usePageProfile from '@features/PageProfile/hooks/usePageProfile'

import ProfileVariationPicker from './ProfileVariationPicker'
import styles from './PageProfileForm.module.css'

const PageProfileForm: FC = () => {
  const { t } = useTranslation()
  const {
    pageProfileFormState: { onChange, formState, pending },
    isProfileVariationAvailable,
  } = usePageProfile()

  return (
    <>
      <ImagePicker
        accept="image/png, image/jpeg"
        pending={pending}
        label={t('label_image')}
        imageSrc={formState.profile.image}
        onChange={(file) =>
          onChange({ target: { name: 'image', value: file } })
        }
      />
      <Spacer height={15} />

      <InputField
        selectValueOnFocus
        name="name"
        label={t('label_name')}
        placeholder={t('profile_name_placeholder')}
        value={formState.profile.name}
        onChange={onChange}
        className={styles.Overflow}
      />
      <Spacer height={15} />

      <InputField
        selectValueOnFocus
        name="title"
        label={t('label_title_optional')}
        placeholder={t('profile_title_placeholder')}
        value={formState.profile.title}
        onChange={onChange}
        className={styles.Overflow}
      />
      <Spacer height={15} />

      <InputField
        selectValueOnFocus
        name="company"
        label={t('profile_description_brand')}
        placeholder={t('profile_description_brand_placeholder')}
        value={formState.profile.company}
        onChange={onChange}
        className={styles.Overflow}
      />
      <Spacer height={15} />

      <If state={isProfileVariationAvailable}>
        <ProfileVariationPicker
          label="Variation"
          name="variation"
          value={formState.profile.variation}
          onChange={onChange}
        />
      </If>
    </>
  )
}

export default PageProfileForm
