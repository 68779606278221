import { parseBoolean } from '@helpers/bool'
import { hash28 } from '@helpers/Base62'
import {
  SharedBoard,
  ShortCutSharedFolder,
} from './models/shortcutDataResponse'
import { Board, Folder, NodeType } from '../app/models'
import { transformNodePage } from './listeners/transformers/NodePageTransformer'
import { transformNodeFolder } from './listeners/transformers/NodeFolderTransformer'

export const transformBoardFromShortcut = (board: SharedBoard): Board => {
  return {
    id: board.id,
    rootFolderId: board.folder.id,
    icon: board.folder.properties.icon,
    title: board.folder.properties.title,
    isPageBoard: false,
    isOwn: false,
    receivedContent: board.receivedContent,
    action: {
      id: board.action,
      undoHead: board.undoHead,
      redoHead: undefined,
    },
    options: {
      checkoutAllowed: parseBoolean(board.options.checkoutAllowed, true),
      formsAllowed: parseBoolean(board.options.formsAllowed, true),
      copyAllowed: parseBoolean(board.options.copyAllowed, true),
      shareAllowed: parseBoolean(board.options.shareAllowed, true),
      insightsAllowed: parseBoolean(board.options.insightsAllowed, false),
      shareFolderAllowed: parseBoolean(board.options.shareFolderAllowed, true),
      notCountedTowardsLimit: parseBoolean(
        board.options.notCountedTowardsLimit,
        false,
      ),
    },
    summary: {
      permissions: {
        read: board.summary?.permissions.read || 0,
        share: board.summary?.permissions.share || 0,
        total: board.summary?.permissions.total || 0,
        write: board.summary?.permissions.write || 0,
      },
    },
  }
}

export const transformFolderFromShortcut = async (
  boardId: string,
  sourceFolder: ShortCutSharedFolder,
  shortcutId: string,
  withNetworkRequests: boolean = true,
): Promise<Folder> => {
  const isPage = sourceFolder.properties.type === NodeType.PAGE

  const content = await Promise.all(
    sourceFolder.content.ids.map(async (id: string) => {
      const node = sourceFolder.content.properties[id]
      const propertyId = hash28(node.text || '')

      const properties =
        sourceFolder.urls?.properties &&
        sourceFolder.urls.properties[propertyId]

      return isPage
        ? transformNodePage(
            boardId,
            sourceFolder.id,
            id,
            node,
            properties,
            withNetworkRequests,
          )
        : transformNodeFolder(id, node, properties)
    }),
  )

  return {
    id: sourceFolder.id,
    icon: sourceFolder.properties.icon,
    title: sourceFolder.properties.title,
    isPage,
    shortcut: shortcutId,
    content,
  }
}
